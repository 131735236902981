<template>
  <section>
    <section v-if="loading">
      <el-skeleton animated>
        <template slot="template">
          <el-skeleton-item variant="image" style="width: 100%; height: 230px;" />
          <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;margin-top:20px">
            <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 80%" />
            </div>
            <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 80%" />
            </div>
            <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 80%" />
            </div>
            <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 100%;margin-bottom: 20px;" />
              <el-skeleton-item variant="text" style="width: 80%" />
            </div>
          </iq-card>
        </template>
      </el-skeleton>
    </section>
    <section v-else class="JCI container970">
      <div class="top-banner">
        <img :src="infoData.banner" style="width: 100%; height: 100%;object-fit:cover" v-if="infoData.banner" />
        <img v-else src="@/assets/images/public/fail.png" alt="" />
        <div>
          <h3>{{ infoData.name }}</h3>
        </div>
      </div>
      <el-card class="table-card" style="padding-top:20px">
        <div class="introduct" v-html="infoData.introduction"></div>
      </el-card>
      <el-card class="table-card mt-4" v-if="infoData.list && infoData.list.length > 0">
        <div class="H3Padding" v-for="(item, i) of infoData.list" :key="i">
          <h3>{{ item.title }}</h3>
          <hr />
          <div class="d-flex flex-wrap heightScroll">
            <div class="smallCard" v-for="v of item.value" :key="v.id" @click="$router.push(`/award-detail?id=${v.id}`)">
              <h5>{{ v.title }}</h5>
              <h6>{{ v.organization }}</h6>
              <p>{{ v.registration_day }}</p>
            </div>
          </div>
          <!-- <div class="d-flex justify-content-end container mt-2">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="6"
              @current-change="handlepageChange()"
              :current-page.sync="page"
              :total="total"
            >
            </el-pagination>
          </div> -->
        </div>
      </el-card>
      <el-card v-else class="table-card mt-3" style="padding-top:20px">
        <div class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
          <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
        </div>
      </el-card>
    </section>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        total: 6,
        page: 1,
        infoData: {},
        loading: true,
      };
    },

    components: {},

    mounted() {
      this.getLevelInfo();
    },

    methods: {
      getLevelInfo() {
        this.loading = true
        this.$http.getProgramList({ type: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            this.infoData = res.data;
          }
          setTimeout(() => {
            this.loading = false
          }, 0)
        });
      },
      handlepageChange(page) {
        this.page = page;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .JCI {
    .heightScroll {
      max-height: 700px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .top-banner {
      height: 230px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      position: relative;

      div {
        height: 230px;
        width: 100%;
        background: rgba($color: #999, $alpha: 0.3);
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        position: absolute;

        >h3 {
          color: #fff;
          font-size: 30px;
        }
      }
    }

    ::v-deep .table-card .el-card__body {
      padding-top: 0px;
    }

    .H3Padding {
      >h3 {
        padding-top: 20px;
      }
    }

    .introduct {
      ::v-deep img {
        width: 100%;
      }
    }

    .smallCard {
      height: 120px;
      padding: 10px;
      margin-top: 10px;
      background: #f8f8f8;
      border: 1px solid #eeeeee;
      border-radius: 5px;

      h5 {
        font-size: 18px;
        font-family: Montserrat, Montserrat-Regular;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      h6 {
        font-size: 18px;
        font-family: Montserrat, Montserrat-Light;
        font-weight: 300;
        color: #333333;
        margin-top: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        font-family: Montserrat, Montserrat-Light;
        font-weight: 300;
        color: #999999;
        margin-top: 10px;
      }
    }
  }
  @media (min-width: 600px) {
    .smallCard {
      width: 300px;
      margin-right: 8px;
    }
  }
  @media (max-width: 600px) {
    .smallCard {
      width: 100%;
    }
  }
</style>